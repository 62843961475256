import { ClaimOrder, Order, Swap } from "@medusajs/medusa"
import React, { useEffect, useState } from "react"
import Button from "../../../../components/fundamentals/button"
import Modal from "../../../../components/molecules/modal"
import Medusa from "../../../../services/api"
import { queryClient } from "../../../../services/config"

type AddNftIdsProps = {
  handleCancel: () => void
  items: any[]
  orderId: string
  wallet: string
}

const AddNftIds: React.FC<AddNftIdsProps> = ({
  handleCancel,
  items,
  wallet,
  orderId,
}) => {
  const [nftIds, setNftIds] = useState<any[]>([])
  const [nftIdsString, setNftIdsString] = useState<string>("")
  const [totalHoodiesQuantity, setTotalHoodiesQuantity] = useState(0)
  const [polygonAddress, setPolygonAddress] = useState(
    !wallet ? "" : wallet.length === 1 ? "" : wallet
  )

  useEffect(() => {
    const quantity = items.reduce((acc, { quantity }) => {
      return acc + quantity
    }, 0)

    setTotalHoodiesQuantity(quantity)
  }, [])

  const saveNftIds = async () => {
    return Medusa.orders
      .addNftIds(orderId, nftIdsString, polygonAddress)
      .then(() => {
        queryClient.invalidateQueries("get_cart_details")
        handleCancel()
      })
  }

  const handleNftIds = (inputValue: string, inputIndex: number) => {
    if (
      (parseInt(inputValue) >= 1 && parseInt(inputValue) <= 300) ||
      inputValue === ""
    ) {
      nftIds[inputIndex] = inputValue
      setNftIds(nftIds)
      setNftIdsString(nftIds.toString())
    }
  }

  const handleWalletChange = (walletInputValue: string) => {
    setPolygonAddress(walletInputValue)
  }

  return (
    <Modal handleClose={handleCancel}>
      <Modal.Body>
        <Modal.Header handleClose={handleCancel}>
          {(!wallet || wallet.length === 1) && (
            <div className="flex flex-col gap-y-4">
              <span className="inter-xlarge-semibold">
                Add Polygon wallet address
              </span>
              <input
                placeholder="Polygon Address"
                value={polygonAddress}
                onChange={(e) => handleWalletChange(e.target.value)}
                type="text"
                className="mb-2 pt-2 pb-2 block w-full px-4 mt-0 bg-transparent border appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
              />
            </div>
          )}
        </Modal.Header>
        <Modal.Content>
          <span className="inter-xlarge-semibold">Add NFT IDs</span>
          <div className="flex flex-col mt-3">
            <span className="inter-base-semibold mb-2">
              Enter ID of each hoodie purchsed by the user. User will be able to
              claim NFTs with those IDs. Make sure IDs are not already claimed
              or assigned to another users!
            </span>
            {totalHoodiesQuantity && (
              <div className="flex w-full flex-wrap">
                {[...Array(totalHoodiesQuantity)].map((_, i) => (
                  <div key={i} className="mr-4">
                    <input
                      value={nftIds[i]}
                      onChange={(e) => handleNftIds(e.target.value, i)}
                      className="text-center shadow appearance-none border w-[80px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-500"
                      type="text"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex w-full h-8 justify-between">
            <div className="flex">
              <Button
                variant="ghost"
                className="mr-2 w-32 text-small justify-center"
                size="large"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="w-32 text-small justify-center"
                variant="primary"
                disabled={
                  nftIds?.length < totalHoodiesQuantity || !polygonAddress
                }
                onClick={saveNftIds}
              >
                Complete
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default AddNftIds
